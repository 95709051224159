import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Ostheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Ostheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <polygon
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Ostheim"
          points="806,682 804.1,712.2 736,734 725,710 716,702 716,684 736,664 736,640 744,646 748,646 748,640 772,642 786,662 794,678"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 737.9229 692.0514)"
        >
          Ostheim
        </text>
      </g>
    </>
  );
});
